import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../app/services/dashboard';
import { RootState } from '../app/store';
import AppInitializationSnapshot from '../features/Dashboard/types/Snapshot';

export type SnapshotState = AppInitializationSnapshot;

const initialState: SnapshotState = {
  hasBorrowerPortalAccount: false,
  exchangeFundingRequests: 0,
  conciergeFundingRequests: 0,
  splitterUrl: '',
  anyInCompleteFundingRequests: false,
  anyOpenFundingRequests: false,
  anyRenewalFundingRequests: false,
  anyClosedFundFundingRequests: false,
  isLendioUX: false,
};

const reducers = {
  setSnapshot: (state: SnapshotState, action: PayloadAction<AppInitializationSnapshot>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(dashboardApi.endpoints.getSnapshot.matchFulfilled, reducers.setSnapshot);
  },
});

export default snapshotSlice.reducer;

// Actions
export const { setSnapshot } = snapshotSlice.actions;

// Selectors
export const selectSnapshot = (state: RootState): AppInitializationSnapshot => state.app.snapshot;
