import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '~/app/hooks';
import { selectCurrentUser } from '~/features/Auth/authSlice';
import { useGetExperienceQuery, useGetSnapshotQuery } from '../../app/services/dashboard';
import ProtectedNavbar from '../Navigation/Navbar';
import MainLayout from './MainLayout';
import './MainLayout.scss';

const ProtectedLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const { isSuccess: isSnapshotSuccess } = useGetSnapshotQuery();
  const { isSuccess: isExperienceSuccess, data: experienceData } = useGetExperienceQuery(
    currentUser?.id ?? ''
  );

  useEffect(() => {
    if (experienceData?.isLendioUX == true) navigate('/lendio');
  }, [experienceData, navigate]);

  return (
    <>
      {isSnapshotSuccess && isExperienceSuccess && !experienceData?.isLendioUX && (
        <MainLayout header={<ProtectedNavbar />} />
      )}
    </>
  );
};

export default ProtectedLayout;
