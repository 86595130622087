import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '~/app/hooks';
import { useGetLendioAppInitDataQuery } from '~/app/services/lendio';
import { Loader } from '~/components/Loader';
import { Modal } from '~/components/Modal';
import { selectCurrentUser } from '../Auth/authSlice';
import GlobalLendio from './types/GlobalLendio';

const LendioLanding = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showErrorModal, setError] = useState(false);
  const qFormGuid = searchParams.get('guid');
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const lendio = (window as GlobalLendio & typeof globalThis).lendio;

  const { isFetching, isSuccess, isError, data: lendioInitData } = useGetLendioAppInitDataQuery({
    qFormGuid: qFormGuid ?? '',
    email: currentUser?.id ?? '',
  });

  useEffect(() => {
    if (!isFetching && isSuccess && lendioInitData) {
      // Verify correct user sees the correct application
      if (currentUser?.id === lendioInitData.user.email) {
        lendio.initialize(lendioInitData);
        lendio.launchLoanApplication();
      } else {
        setError(true);
      }
    }
  }, [isFetching, isSuccess, lendioInitData, currentUser, lendio]);

  useEffect(() => {
    if (showErrorModal || isError)
      setTimeout(() => {
        navigate('/logout', { replace: true });
      }, 3000);
  }, [showErrorModal, isError, navigate]);

  // Handle events returned by Lendio app
  useEffect(() => {
    lendio.setEventHandler('authenticationFailed', () => {
      navigate('/logout');
    });

    lendio.setEventHandler('close', () => {
      navigate('/logout');
    });
  });

  return (
    <>
      <Helmet>
        <title>Lendio - Business.LendingTree.com</title>
      </Helmet>
      <Modal
        centerContent={true}
        show={showErrorModal || isError}
        size="medium"
        title="An error occurred loading your experience"
        onClose={() => {
          navigate('/logout');
        }}
      >
        <div>
          <span>Redirecting...</span>
          <Loader centered={true} size="small" />
        </div>
      </Modal>
    </>
  );
};

export default LendioLanding;
